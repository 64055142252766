import React from "react";

export default function About() {
  return (
    <>
      <p>
        Web Application Developer with more than 12 years of experience on
        Laravel, Joomla and Wordpress. My current working scope is an
        Professional Joomla Extension Developer, SP Page Builder is one of the
        main project I'm currently working on. PHP, ReactJS and MySQL is the key
        technology for all of my projects.
      </p>
    </>
  );
}
