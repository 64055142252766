import React from "react";

export default function Contact() {
  return (
    <>
      <p className="text-center">
        <i class="fas fa-envelope"></i> siddikcoder@gmail.com
      </p>
    </>
  );
}
