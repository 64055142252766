import { Link, Outlet } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";

function App() {
  return (
    <div className="container">
      <Home />
      <nav className="nav justify-content-center">
        <Link className="nav-link" to="/">
          Home
        </Link>
        <Link className="nav-link" to="/about-us">
          About us
        </Link>
        <Link className="nav-link" to="/terms-and-conditions">
          Terms and Conditions
        </Link>
        <Link className="nav-link" to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link className="nav-link" to="/contact-us">
          Contact us
        </Link>
      </nav>

      <Outlet />
    </div>
  );
}

export default App;
