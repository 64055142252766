import React from "react";
import Logo from ".././md-siddiqur-rahman.svg";

export default function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="text-center p-5">
            <img
              alt="Md Siddiqur Rahman"
              src={Logo}
              className="img-fluid rounded-circle"
            />
            <h1>Md Siddiqur Rahman</h1>
            <h2 className="text-muted">
              Senior Staff Software Engineer @
              <a href="https://ollyo.com" rel="noreferrer" target="_blank">
                Ollyo
              </a>
            </h2>
            <a
              rel="noreferrer"
              href="https://github.com/siddik-web"
              target="_blank"
              className="btn btn-default"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/in/siddiqur/"
              target="_blank"
              className="btn btn-default"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/imsiddiqur"
              target="_blank"
              className="btn btn-default"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://facebook.com/imsiddiqur"
              target="_blank"
              className="btn btn-default"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </div>
          <div>
          <p>
      I specialize in crafting custom Joomla extensions, showcasing a wealth of experience in custom development. My proficiency extends to seamlessly converting diverse Joomla custom extensions, ensuring compatibility across multiple versions, from Joomla 3 to the latest Joomla 5. Moreover, I prioritize future-proofing by providing robust PHP 8 support, guaranteeing optimal performance and security for your Joomla-powered projects.
      </p>
      <p>
      As part of my portfolio, I've successfully contributed to projects such as <a href="https://www.joomshaper.com/page-builder" title="SP Page Builder" target="_blank" rel="noreferrer">SP Page Builder</a> and <a href="https://www.joomshaper.com/easystore" title="EasyStore" target="_blank" rel="noreferrer">EasyStore</a>. These ventures stand as a testament to my ability to deliver high-quality solutions and adapt seamlessly to popular Joomla extensions, enhancing functionality and compatibility.
      </p>
          </div>
        </div>
      </div>
    </div>
  );
}
